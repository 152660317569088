import React from 'react';

export function Logo({
    width=18,
}: {
    width?: number,
}) {
    return (
        <svg width={width} height={width} viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M66.8449 2L0 200.113H64.5808C77.303 200.113 83.6887 196.232 89.9906 184.698C90.9722 182.901 91.8044 181.215 92.5693 179.666C95.6934 173.336 97.6936 169.283 104.162 169.283H155.615L166.845 200.113H200L132.086 2H66.8449ZM94.1176 15.1323L42.5607 169.855H84.4919C88.77 169.855 91.7119 168.699 93.8914 164.145L98.6631 155.011C104.813 141.879 113.904 140.166 125.936 140.166H145.454L104.278 15.1323C102.674 11.1357 95.1871 11.7069 94.1176 15.1323Z" fill="#C1FF05"/>
        </svg>
    )
}