import { User } from 'firebase/auth';
import React from 'react';
import { Link } from 'react-router-dom';
import { useFirebaseAuthUser } from './Hooks/useFirebaseAuth';
import { Logo } from './Logo';


export const navBarButtonClassName = 'block font-medium rounded-lg text-lg title-font text-black font-medium select-none cursor-pointer bg-lime-500 hover:bg-lime-400 transition-colors px-4 py-2 shadow-md'

export function NavBarContainer({
    children,
}: {
    children?: React.ReactNode,
}) {
    return (
        <div className='flex flex-col sm:flex-row items-center justify-center w-full py-4 text-sm md:text-base z-50'>
            <Link to="/" className='flex-none flex flex-row items-center justify-center my-4 h-5 sm:h-7 cursor-pointer'>
                <Logo width={22}/>
                <div className='text-2xl title-font flex-1 text-white mx-3 select-none'>
                    Ando
                </div>
            </Link>
            <div className='grow'></div>
            <div className='my-4 sm:my-0 flex flex-row items-center'>
                <div className='flex flex-row mr-8'>
                    <NavBarLink to="/pricing">Pricing</NavBarLink>
                    <NavBarLink to="/styles">Styles</NavBarLink>
                </div>
                {children}
            </div>
        </div>
    )
}

function NavBarLoginButton() {
    return (
        <a 
            href={'/figmaLogin'}
            className={navBarButtonClassName}
        >
            Get Started
        </a>
    )
}

function NavBarUserProfile({
    user,
}: {    
    user: User,
}) {
    return (
        <Link to="/figmaLogin" className='w-[38px] h-[38px] flex justify-center items-center text-center rounded-full bg-lime-500 text-lg font-bold text-zinc-800 shadow-md'>
            {(user.email?.[0] || 'U').toUpperCase()}
        </Link>
    )
}

function NavBarLink({
    to,
    children,
}: {
    to: string,
    children: React.ReactNode,
}) {
    return (
        <Link to={to} className='mx-4 text-zinc-300 hover:text-zinc-100 transition-colors'>
            {children}
        </Link>
    )
}

export function NavBarMain() {
    const user = useFirebaseAuthUser();

    return (
        <NavBarContainer>
            {user?.uid ?
                <NavBarUserProfile user={user}/>:
                <NavBarLoginButton/>
            }
        </NavBarContainer>
    )
}