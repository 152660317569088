import React, { useEffect } from 'react';
import { AppContainer } from './AppContainer';
import {NavBarContainer, navBarButtonClassName, NavBarMain} from './NavBar';
import Typewriter from 'typewriter-effect';

const IMAGE_ANIMATION_DURATION = 8;
const TYPE_ANIMATION_DURATION = 1;
const LEFT_ANIMATION_DURATION = 1;

const figmaLink = 'https://www.figma.com/community/plugin/1145446664512862540'

function Plugin({
    title,
    prompt,
}: {
    title?: string,
    prompt: string,
}) {
    return (
        <div className='relative'>
            <div className='relative flex flex-row bg-zinc-900 rounded-lg overflow-hidden z-10 opacity-80 outline outline-1 outline-zinc-800/80'>
                <div 
                    className='relative w-[350px]'
                >
                    <div 
                        className='absolute z-10 text-zinc-300'
                        style={{
                            width: '310px',
                            top: '95px',
                            left: '20px',
                        }}
                    >
                        <div
                            className='h-[108.5px]'
                        >
                            <div
                                className='w-full text-left text-xs mx-3'
                            >
                                <Typewriter
                                    options={{
                                        strings: [prompt],
                                        autoStart: true,
                                        delay: 15,
                                        //@ts-ignore
                                        deleteSpeed: 10,
                                        // @ts-ignore
                                        pauseFor: 5000,
                                    }}
                                />
                            </div>
                        </div>
                        <div className='mb-[170px] px-[80px] text-left text-xs text-zinc-300/80 select-none'>{title}</div>
                        <a 
                            href={figmaLink}
                            className='block w-[310px] rounded-md text-xs font-semibold py-2 bg-lime-500 hover:bg-lime-400 transition-colors text-zinc-800 select-none cursor-pointer'
                        >
                            Render
                        </a>
                    </div>
                    <img 
                        alt="screenshot" 
                        src="https://ik.imagekit.io/remitnotpaucity/ando/landing/ando_screenshot_zOwmjeWnv.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662180384122"
                        className='hero-shadow-inner'
                        style={{
                            opacity: 0.9,
                        }}
                    />
                </div>
            </div>
            <div 
                className='absolute w-[350px] h-[420px] gradient-shadow z-0 animate-scale'
                style={{
                    top: '0px'
                }}
            />
        </div>
    )
}


const heroImages = [
    {
        prompt: 'A pavilion designed by Kazuyo Sejima, curved light-weight ceiling, glass, metal pillars',
        leftBackground: 'url("https://ik.imagekit.io/remitnotpaucity/ando/landing/interior4_1_PgxKQLUUe.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662226305119")',
        rightBackground: [
            'url("https://ik.imagekit.io/remitnotpaucity/ando/landing/interior4_2_yBmdhThVI.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662226305448")',
            'url("https://ik.imagekit.io/remitnotpaucity/ando/landing/interior_2_E_c_9T964.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662263521569")',
        ],
    },
    {
        prompt: 'futuristic sneaker with translucent material, studio lighting, product photography',
        leftBackground: 'url("https://ik.imagekit.io/remitnotpaucity/ando/landing/sneaker1_1_RzBycsLOL.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662184607664")',
        rightBackground: [
            'url("https://ik.imagekit.io/remitnotpaucity/ando/landing/sneaker1_2_dmoSQ7Ekz.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662184607661")',
            'url("https://ik.imagekit.io/remitnotpaucity/ando/landing/sneaker1_3_aEVJEnDY_.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662229825426")',
        ]
    },
    {
        prompt: 'concrete interior designed by tadao ando',
        leftBackground: 'url("https://ik.imagekit.io/remitnotpaucity/ando/landing/ando1_1_hBBcMTFzK.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662224603680")',
        rightBackground: 'url("https://ik.imagekit.io/remitnotpaucity/ando/landing/ando1_2_SZgkRbr6V.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662224603677")'
    },
    {
        prompt: 'gray concrete pavilion with surface material details designed by Tadao Ando, architecture photography',
        leftBackground: 'url("https://ik.imagekit.io/remitnotpaucity/ando/landing/interior2_1_SpFjS7eqc0.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662226305557")',
        rightBackground: [
            'url("https://ik.imagekit.io/remitnotpaucity/ando/landing/interior2_2_Zq2SORITpl.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662226305414")',
            'url("https://ik.imagekit.io/remitnotpaucity/ando/landing/interior2_3_Lp-LF2bWw.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662231310575")'
        ]
    },
    {
        prompt: 'futuristic sneaker designed by zaha hadid',
        leftBackground: 'url("https://ik.imagekit.io/remitnotpaucity/ando/landing/sneaker2_1_tm1vnGF5cw.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662229866550")',
        rightBackground: [
            'url("https://ik.imagekit.io/remitnotpaucity/ando/landing/sneaker2_2_WV8a-am8c.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662229638321")',
            'url("https://ik.imagekit.io/remitnotpaucity/ando/landing/sneaker2_5_3_jsgGqYv.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662229943005")',
        ]
    }
]

function RightVerticalImageSet({
    imageIndex,
}: {
    imageIndex: number,
}) {
    const images = heroImages[imageIndex].rightBackground;
    return (
        <div className='flex flex-col z-0 h-[512px] pt-[50px]'>
            {
                Array.isArray(images)
                ? images.map((background, index) => (
                    <div 
                        key={`${imageIndex}_${index}`}
                        className='w-[256px] h-[256px] bg-zinc-100 z-50 rounded-lg shadow-lg my-2'
                        style={{
                            background,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                        }}
                    />
                ))
                :   <div 
                        key={imageIndex}
                        className='w-[256px] h-[256px] bg-zinc-100 z-50 rounded-lg shadow-lg'
                        style={{
                            background: images,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                        }}
                    />
            }
        </div>
    )
}


function Hero() {
    const [imageIndex, setImageIndex] = React.useState(0);
    useEffect(() => {
        const imageIntervalId = setInterval(() => {
            setImageIndex(index => (index + 1) % heroImages.length)
        }, IMAGE_ANIMATION_DURATION * 1000);
        return () => {
            clearInterval(imageIntervalId);
        }
    }, []);
    return (
        <div className='flex flex-row items-center my-20'>
            <div 
                key={`${imageIndex}-left`}
                className='flex flex-row items-center mb-32'
                style={{
                    opacity: 0,
                    animationName: 'fade-in-out-animation',
                    animationDuration: `${IMAGE_ANIMATION_DURATION - TYPE_ANIMATION_DURATION}s`,
                    animationTimingFunction: 'ease-in-out',
                    animationDelay: `${TYPE_ANIMATION_DURATION}s`
                }}
            >
                <div 
                    className='w-[256px] h-[256px] bg-zinc-100 z-50 rounded-lg shadow-lg mb-20'
                    style={{
                        background: heroImages[imageIndex].leftBackground,
                        backgroundSize: 'cover',
                    }}
                />
                <svg width="120" height='125' viewBox='0 0 100 100'>
                    <path className='hero-line' stroke='#bef264' fill='none' strokeWidth="1" d="M 0 0 L 30 0 A 20,20 45 0 1 50 20 L 50 80 A 20,20 45 0 0 70 100 L 100 100"/>
                    <path stroke='#84cc16' fill='none' opacity={0.8} strokeWidth="2" d="M 0 0 L 30 0 A 20,20 45 0 1 50 20 L 50 80 A 20,20 45 0 0 70 100 L 100 100"/>
                </svg>
            </div>
            <Plugin
                prompt={heroImages[imageIndex].prompt}
                title={`${imageIndex} / ${heroImages.length}`}
            />
            <div
                key={`${imageIndex}-right`} 
                className='flex flex-row items-center'
                style={{
                    opacity: 0,
                    animationName: 'fade-in-out-animation',
                    animationDuration: `${IMAGE_ANIMATION_DURATION - (TYPE_ANIMATION_DURATION + LEFT_ANIMATION_DURATION)}s`,
                    animationTimingFunction: 'ease-in-out',
                    animationDelay: `${TYPE_ANIMATION_DURATION + LEFT_ANIMATION_DURATION}s`
                }}
            >
                <svg width="120" height="125" viewBox='0 0 100 100'>
                    <path className='hero-line' stroke='#bef264' strokeWidth="1" d="M0 50 L100 50"/>
                    <path stroke='#84cc16' opacity={0.8} strokeWidth="2" d="M0 50 L100 50"/>
                </svg>
                <RightVerticalImageSet imageIndex={imageIndex}/>
            </div>
        </div>
    )
}

export function Home() {
    return (
        <AppContainer>
            <NavBarMain/>
            <div
                className='w-full py-20 flex flex-col justify-center items-center'
            >
                <div className='text-3xl title-font text-zinc-200'>Meet <span className='text-lime-500'>Ando</span>,</div>
                <div className='text-5xl my-6 title-font'>Your <span className='text-lime-500'>AI</span> design copilot.</div>
                <div className='text-xl my-2 text-zinc-500'>
                    Generate design ideas from texts, shapes, and images, right in Figma.
                </div>
                <Hero/>
            </div>
        </AppContainer>
    )
}